import {INPUT_NAMES} from '@wix/wix-events-commons-statics'
import {TranslationFunction} from 'i18next'
import {RsvpErrorKey, State} from '../types'

export const shouldNavigateToForm = (state: State) => {
  const eventControls = state?.event?.form?.controls
  const rsvpControls = state?.memberRsvp?.rsvp?.rsvpForm?.inputValues

  if (eventControls && rsvpControls) {
    const filteredEventControls = eventControls
      .filter(({name}) => name !== INPUT_NAMES.NAME && name !== INPUT_NAMES.EMAIL)
      .map(({name}) => name)
      .sort()
    const filteredRsvpControls = rsvpControls
      .filter(
        ({inputName}) =>
          inputName !== INPUT_NAMES.EMAIL &&
          inputName !== INPUT_NAMES.FIRST_NAME &&
          inputName !== INPUT_NAMES.LAST_NAME &&
          inputName !== INPUT_NAMES.GUEST_NAMES,
      )
      .map(({inputName}) => inputName)
      .sort()
    const eventControlsContainsAllRsvpControls = filteredEventControls.every(val => filteredRsvpControls.includes(val))

    return filteredEventControls.length < filteredRsvpControls.length || !eventControlsContainsAllRsvpControls
  } else {
    return false
  }
}

export const getRsvpError = (state: State, t: TranslationFunction) => {
  const handlers = {
    [RsvpErrorKey.RSVP_CLOSED]: () => t('formError.rsvpClosed'),
    [RsvpErrorKey.GUEST_LIMIT_REACHED]: () =>
      t('formError.guestLimitExceeded', {remainingSlots: state.memberRsvp.error.max_rsvp_size}),
    [RsvpErrorKey.WAITING_LIST_UNAVAILABLE]: () => t('formError.waitingListUnavailable'),
    [RsvpErrorKey.GUEST_ALREADY_REGISTERED]: () => t('formError.guestAlreadyRegistered'),
    [RsvpErrorKey.MEMBER_ALREADY_REGISTERED]: () => t('formError.guestAlreadyRegistered'),
    [RsvpErrorKey.UNEXPECTED_RSVP_RESPONSE]: () => t('formError.invalidRsvp'),
    [RsvpErrorKey.UNKNOWN_RSVP_ERROR]: () => t('formError.generalError'),
  }

  const errorHandlerKey = Object.keys(handlers).find(key => key === state.memberRsvp.error?.error_key)

  return errorHandlerKey && handlers[errorHandlerKey]()
}
