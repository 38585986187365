import {CommonState} from '../types/state'

const isExperimentEnabled = (state: CommonState, name: string) => state.experiments[name] === 'true'

export const getExperiments = (state: CommonState) => state.experiments

export const isReactWidgetSettingsEnabled = (state: CommonState) =>
  isExperimentEnabled(state, 'specs.events.ui.ReactWidgetSettings')

export const isEventsPaidPlansEnabled = (state: CommonState) =>
  isExperimentEnabled(state, 'specs.events.ui.EventsPaidPlans')
