import {combineReducers} from 'redux'
import {demoEvents} from '../../commons/reducers/demo-events'
import {environment} from '../../commons/reducers/environment'
import {experiments} from '../../commons/reducers/experiments'
import {multilingual} from '../../commons/reducers/multilingual'
import {siteSettings} from '../../commons/reducers/site-settings'
import {State} from '../types'
import {checkoutOptions} from './checkout-options'
import {component} from './component'
import {currentMemberDetails} from './current-member-details'
import {event} from './event'
import {memberRsvp} from './member-rsvp'
import {membersAreaEnabled} from './members-area-enabled'
import {navigation} from './navigation'
import {order} from './order'
import {paidPlans} from './paid-plans'
import {pendingRequests} from './pending-requests'
import {reservation} from './reservation'
import {selectedTickets} from './selected-tickets'
import {tickets} from './tickets'
import {ticketsPicker} from './tickets-picker'

export default combineReducers<State>({
  experiments,
  environment,
  siteSettings,
  multilingual,
  demoEvents,
  event,
  component,
  navigation,
  order,
  currentMemberDetails,
  memberRsvp,
  membersAreaEnabled,
  checkoutOptions,
  selectedTickets,
  pendingRequests,
  tickets,
  reservation,
  ticketsPicker,
  paidPlans,
})
