import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types'
import {InvoiceBuilder, OrderBuilder} from '@wix/wix-events-commons-statics'
import {PlanList} from '@wix/wix-events-commons-statics/dist/es/types/exported-types'
import {Api as BaseApi} from '../../commons/utils/api'
import {getLanguage, getViewMode} from '../../commons/utils/wix-code-api'
import {GET_CHECKOUT_OPTIONS} from '../actions/checkout-options'
import {GET_DISCOUNT} from '../actions/coupon'
import {GET_EVENT, GET_MEMBERS} from '../actions/event'
import {PROMPT_LOGIN} from '../actions/members'
import {GET_PLAN_LIST} from '../actions/paid-plans'
import {CANCEL_RESERVATION, RESERVE_TICKETS} from '../actions/reservation'
import {GET_MEMBER_RSVP, SEND_RSVP, UPDATE_RSVP} from '../actions/rsvp'
import {RegFormData, SelectedTickets} from '../types'
import TicketReservationQuantity = wix.events.ticketing.TicketReservationQuantity

export class Api extends BaseApi {
  constructor(controller: IWidgetControllerConfig) {
    super(controller)

    this.registrar = {
      [GET_EVENT.NAME]: this.getEvent,
      [GET_MEMBERS.NAME]: this.getMembers,
      [GET_DISCOUNT.NAME]: this.getInvoice,
      [GET_CHECKOUT_OPTIONS.NAME]: this.getCheckoutOptions,
      [RESERVE_TICKETS.NAME]: this.makeReservation,
      [CANCEL_RESERVATION.NAME]: this.cancelReservation,
      [GET_MEMBER_RSVP.NAME]: this.getMemberRsvp,
      [SEND_RSVP.NAME]: this.createRsvp,
      [UPDATE_RSVP.NAME]: this.updateRsvp,
      [PROMPT_LOGIN.NAME]: this.promptLogin,
      [GET_PLAN_LIST.NAME]: this.getPlanList,
    }
  }

  getData = (slug: string) => {
    const {compId, wixCodeApi} = this.controller
    const language = getLanguage(wixCodeApi)
    const viewMode = getViewMode(wixCodeApi)
    const url = `/html/page-data/${slug}?instance=${this.getInstance()}&compId=${compId}&locale=${language}&viewMode=${viewMode}`

    return this.api.get(this.appendLinguisticParams(this.appendPetriOvr(url)))
  }

  getOrder = async (): Promise<wix.events.ticketing.Order> => {
    const data: wix.events.ticketing.GetOrderResponse = {
      order: new OrderBuilder().withInvoice(new InvoiceBuilder().build()).build(), // TODO fix
    }

    return data.order
  }

  getEvent = (eventId: string, membersEnabled = false) =>
    this.api.get(`/web/events/${eventId}/viewer?members=${membersEnabled}`)

  getMembers = (eventId: string): Promise<{members: Member[]; totalGuests: TotalEventGuests}> =>
    this.api.get(`/web/events/${eventId}/members`)

  getInvoice = (
    eventId: string,
    reservationId: string,
    couponCode?: string,
  ): Promise<{id: string; expires: string}> => {
    return this.api.put(`/web/events/${eventId}/invoice`, {reservationId, couponCode})
  }

  getCheckoutOptions = (): Promise<wix.events.ticketing.GetCheckoutOptionsResponse> =>
    this.api.get('/web/checkout-options')

  makeReservation = (eventId: string, tickets: SelectedTickets): Promise<{id: string; expires: string}> => {
    const ticketQuantities: TicketReservationQuantity[] = Object.entries(tickets).map(
      ([ticketDefinitionId, quantity]) => ({
        ticketDefinitionId,
        quantity,
      }),
    )
    return this.api.post(`/web/events/${eventId}/reservations`, ticketQuantities)
  }

  cancelReservation = (eventId: string, reservationId: string) =>
    this.api.delete(`/web/events/${eventId}/reservations/${reservationId}`)

  getMemberRsvp = async (eventId: string, memberId: string): Promise<wix.events.rsvp.Rsvp> => {
    return this.api.get(`/web/events/${eventId}/member-rsvp?memberId=${memberId}`).then(response => response.rsvp)
  }

  getCurrentMember = (): Promise<{member: {lastName: string; firstName: string; emails: string[]; id: string}}> =>
    this.api.get(`/web/members/current`)

  createRsvp = (
    eventId: string,
    data: RegFormData,
    status: wix.events.rsvp.RsvpStatus,
    memberId?: string,
  ): Promise<wix.events.rsvp.CreateRsvpResponse> => {
    return this.api.post(`/web/events/${eventId}/v2/rsvp`, {memberId, status, data})
  }

  updateRsvp = (
    eventId: string,
    data: RegFormData,
    status: wix.events.rsvp.RsvpStatus,
    rsvpId: string,
  ): Promise<wix.events.rsvp.UpdateRsvpResponse> => {
    return this.api.put(`/web/events/${eventId}/rsvp/${rsvpId}/form`, {data, status})
  }

  promptLogin = (lang: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        await this.controller.wixCodeApi.user.promptLogin({
          mode: 'login',
          lang,
        })
        resolve(true)
      } catch (e) {
        if (typeof e !== 'string') {
          reject(e)
          return
        }
        resolve(false)
      }
    })
  }

  getPlanList = (eventId: string): Promise<PlanList> => this.api.get(`/web/plans?eventId=${eventId}`)
}
