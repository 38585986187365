import {IWidgetControllerConfig, IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {IWixStyleParams} from '@wix/native-components-infra/dist/src/types/wix-sdk'
import {createUouBiMiddlewareWithBiParams, isRtlLanguage, BiParams} from '@wix/wix-events-commons-statics'
import {bindActionCreators, Store} from 'redux'
import {setBaseEnvironment} from '../../commons/actions/environment'
import {getLanguage} from '../../commons/selectors/environment'
import {initSentry, ErrorMonitor} from '../../commons/services/error-monitor'
import {getExperimentsByScope} from '../../commons/utils/experiments'
import {isMembersEventsPageInstalled} from '../../commons/utils/members-api'
import {createReduxStore, subscribeToStateChanges} from '../../commons/utils/store'
import {isSSR} from '../../commons/utils/wix-code-api'
import {getCheckoutOptions} from '../actions/checkout-options'
import {updateStyleParams} from '../actions/component'
import {getDiscount, resetCouponCode, setCouponCode} from '../actions/coupon'
import {getMembers} from '../actions/event'
import {detailsPageLoaded, formPageLoaded, thankYouPageLoaded, ticketedThankYouPageLoaded} from '../actions/loaded'
import {promptLogin} from '../actions/members'
import {handleExternalRedirect, navigate, navigateToForm, navigateToMainPage} from '../actions/navigation'
import {getPlanList, openMembershipPicker} from '../actions/paid-plans'
import {registrationButtonClicked} from '../actions/registration'
import {reserveTickets} from '../actions/reservation'
import {
  changeMemberRsvp,
  ensureLoginForMembersOnly,
  getMemberRsvp,
  resetRsvpError,
  sendRsvp,
  updateRsvp,
} from '../actions/rsvp'
import {selectTicket, unselectTicket} from '../actions/selected-tickets'
import {collapseDescription, expandDescription} from '../actions/tickets-picker'
import * as eventsUou from '../bi/uou-bi-events-map'
import {DetailsPageProps} from '../components/app/interfaces'
import reducers from '../reducers'
import {createDetailsPageFedopsLogger} from '../services/fedops'
import {Actions, State, StoreExtraArgs} from '../types'
import {Api} from '../utils/api'
import {getRouteParams, parsePath} from '../utils/navigation'

const DSN = 'https://588ca41dab294885b034d58caffde32b@sentry.wixpress.com/310'

export const createDetailsPageController = async (controller: IWidgetControllerConfig) => {
  const monitor = initSentry(controller, DSN)
  return Promise.resolve({
    pageReady: monitor.withErrorBoundary(async () => await pageReady(controller, monitor)),
  })
}

const pageReady = async (controller: IWidgetControllerConfig, monitor: ErrorMonitor) => {
  const fedopsLogger = createDetailsPageFedopsLogger(controller)
  const store = await createStore(controller)
  const state = store.getState()
  const actions = exportedActions(store, fedopsLogger.onAppLoaded, monitor)
  const pageUrl = await controller.wixCodeApi.site.getSectionUrl({sectionId: 'events'})

  const props: DetailsPageProps = {
    state,
    actions,
    isRTL: isRtlLanguage(getLanguage(state)),
    staticsBaseUrl: controller.appParams.baseUrls.staticsBaseUrl,
    pageUrl,
  }

  controller.setProps(props)

  if (isSSR(controller.wixCodeApi)) {
    fedopsLogger.onSSRPageReady()
  }

  handleExternalRedirect(controller.wixCodeApi, state)
  addLocationListener(controller.wixCodeApi, store)
}

const createBiMiddleware = (biParams: BiParams) => [
  //createUsersBiMiddlewareWithBiParams(biParams, eventsUsers),
  createUouBiMiddlewareWithBiParams(biParams, eventsUou),
]

const createStore = async (controller: IWidgetControllerConfig) => {
  const {wixCodeApi, compId, config} = controller
  const serverApi = new Api(controller)

  const biMiddleware = createBiMiddleware({
    wixCodeApi,
    platformAPIs: controller.platformAPIs,
    appParams: controller.appParams,
    compId: controller.compId,
    user: {}, // TODO ?
  })

  //const userEventsLoggerMiddleware = userEventsLogger({wixCodeApi})
  const initialData = await getInitialData(serverApi, wixCodeApi, config.style.styleParams)

  const store = createReduxStore<State, StoreExtraArgs>({
    reducers,
    initialData,
    extraArguments: {serverApi, wixCodeApi, compId},
    middleware: [...biMiddleware],
  })

  await store.dispatch(<any>setBaseEnvironment())
  // await store.dispatch(<any>navigate(parsePath(wixCodeApi.location.path).route))

  await store.dispatch(<any>getMemberRsvp())

  subscribeToStateChanges(controller, store)

  return store
}

const getInitialData = async (
  serverApi: Api,
  wixCodeApi: IWixAPI,
  styleParams: IWixStyleParams,
): Promise<Partial<State>> => {
  const navigation = parsePath(wixCodeApi.location.path)
  const membersAreaEnabled = await isMembersEventsPageInstalled(wixCodeApi)

  const [experiments, {event, component, siteSettings, demoEvents, tickets}, order, {member}] = await Promise.all([
    getExperimentsByScope(),
    serverApi.getData(navigation.slug),
    navigation.reservationId ? serverApi.getOrder() : null,
    membersAreaEnabled ? serverApi.getCurrentMember() : null,
  ])

  return {
    event,
    experiments,
    siteSettings,
    demoEvents,
    tickets,
    navigation,
    multilingual: {
      currentLanguage: wixCodeApi.window.multilingual.currentLanguage,
      isEnabled: wixCodeApi.window.multilingual.isEnabled,
    },
    component: {
      id: component.id,
      settings: {
        ...component.config.settings,
        ...styleParams.numbers,
        ...styleParams.booleans,
      },
    },
    order,
    membersAreaEnabled,
    currentMemberDetails: {
      id: member?.id,
      email: member?.loginEmail,
      firstName: member?.firstName,
      lastName: member?.lastName,
    },
  }
}

const exportedActions = (store: Store, appLoaded, monitor): Actions => {
  const dispatchActions = {
    navigateToForm,
    navigateToMainPage,
    detailsPageLoaded,
    formPageLoaded,
    thankYouPageLoaded,
    ticketedThankYouPageLoaded,
    changeMemberRsvp,
    registrationButtonClicked,
    updateStyleParams,
    getDiscount,
    setCouponCode,
    resetCouponCode,
    resetRsvpError,
    sendRsvp,
    updateRsvp,
    reserveTickets,
    selectTicket,
    unselectTicket,
    getCheckoutOptions,
    ensureLoginForMembersOnly,
    expandDescription,
    collapseDescription,
    getMembers,
    getPlanList,
    openMembershipPicker,
    promptLogin,
  }

  const actions: Actions = {
    ...bindActionCreators(dispatchActions, store.dispatch),
    appLoaded,
  }

  return monitor.bindActions(actions)
}

const addLocationListener = (wixCodeApi: IWixAPI, store: Store) =>
  wixCodeApi.location.onChange(({path}) => store.dispatch(<any>navigate(parsePath(path).route, getRouteParams(path))))
