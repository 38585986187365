import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types'
import {EVENTS_DETAILS_PAGE_ID, EVENTS_MEMBERS_WIDGET_ID, EVENTS_WIDGET_ID} from '@wix/wix-events-commons-statics'
import {ExperimentsBag} from '@wix/wix-experiments'
import {getExperimentsByScope} from './commons/utils/experiments'
import {createDetailsPageController} from './details-page/controllers'
import {createMembersPageController as createMembersAppController} from './members-page/controllers/members-page'
import {createWidgetController} from './widget/controllers'

let experiments: ExperimentsBag

export const initAppForPage = async () => {
  experiments = await getExperimentsByScope('client-spec-map')
}

export const createControllers = (controllerConfigs: IWidgetControllerConfig[]) =>
  controllerConfigs.map(c => {
    if (c.type === EVENTS_WIDGET_ID) {
      return createWidgetController(c)
    } else if (c.type === EVENTS_MEMBERS_WIDGET_ID) {
      return createMembersAppController(c)
    } else if (
      c.type === EVENTS_DETAILS_PAGE_ID &&
      (experiments['specs.events.ui.PageOOIRegistered'] === 'true' || experiments['specs.events.ui.PageOOI'] === 'true')
    ) {
      return createDetailsPageController(c)
    }
  })
