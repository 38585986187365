import {PlanList} from '@wix/wix-events-commons-statics/dist/es/types/exported-types'
import {CommonState, CommonStoreExtraArgs, DemoEvents} from '../../commons/types/state'
import {getCheckoutOptions} from '../actions/checkout-options'
import {updateStyleParams} from '../actions/component'
import {getDiscount, resetCouponCode, setCouponCode} from '../actions/coupon'
import {getMembers} from '../actions/event'
import {detailsPageLoaded, formPageLoaded, thankYouPageLoaded, ticketedThankYouPageLoaded} from '../actions/loaded'
import {promptLogin} from '../actions/members'
import {navigateToForm, navigateToMainPage} from '../actions/navigation'
import {getPlanList, openMembershipPicker} from '../actions/paid-plans'
import {registrationButtonClicked} from '../actions/registration'
import {reserveTickets} from '../actions/reservation'
import {changeMemberRsvp, ensureLoginForMembersOnly, resetRsvpError, sendRsvp, updateRsvp} from '../actions/rsvp'
import {selectTicket, unselectTicket} from '../actions/selected-tickets'
import {collapseDescription, expandDescription} from '../actions/tickets-picker'
import {Api} from '../utils/api'
import {RsvpErrorMetadata} from './errors'
import {Alignment, ButtonStyle, ComponentTexts, ImagePosition, ImageRatio} from './settings'

export interface Actions {
  appLoaded: Function
  navigateToForm: typeof navigateToForm
  navigateToMainPage: typeof navigateToMainPage
  detailsPageLoaded: typeof detailsPageLoaded
  formPageLoaded: typeof formPageLoaded
  thankYouPageLoaded: typeof thankYouPageLoaded
  ticketedThankYouPageLoaded: typeof ticketedThankYouPageLoaded
  changeMemberRsvp: typeof changeMemberRsvp
  registrationButtonClicked: typeof registrationButtonClicked
  updateStyleParams: typeof updateStyleParams
  getDiscount: typeof getDiscount
  setCouponCode: typeof setCouponCode
  resetCouponCode: typeof resetCouponCode
  resetRsvpError: typeof resetRsvpError
  sendRsvp: typeof sendRsvp
  updateRsvp: typeof updateRsvp
  reserveTickets: typeof reserveTickets
  selectTicket: typeof selectTicket
  unselectTicket: typeof unselectTicket
  getCheckoutOptions: typeof getCheckoutOptions
  ensureLoginForMembersOnly: typeof ensureLoginForMembersOnly
  expandDescription: typeof expandDescription
  collapseDescription: typeof collapseDescription
  getMembers: typeof getMembers
  promptLogin: typeof promptLogin
  openMembershipPicker: typeof openMembershipPicker
  getPlanList: typeof getPlanList
}

export interface State extends CommonState {
  event: ExtendedEvent
  demoEvents: DemoEvents
  navigation: Navigation
  component: Component
  order: wix.events.ticketing.Order
  currentMemberDetails: CurrentMemberDetails
  membersAreaEnabled: boolean
  memberRsvp: MemberRsvp
  reservation: Reservation
  checkoutOptions: CheckoutOptionsState
  selectedTickets: SelectedTickets
  pendingRequests: PendingRequests
  tickets: wix.events.ticketing.TicketDefinition[]
  ticketsPicker: TicketsPicker
  paidPlans: PaidPlansState
}

export interface PaidPlansState {
  enabled: boolean
  planList: PlanList
  purchasedPlanList: PlanList
  selectedPaidPlanId: string
}

export interface Reservation {
  data: ReservationData
  error: boolean
}

interface ReservationData {
  id: string
  expires: string
}

export type GetState = () => State

export interface StoreExtraArgs extends CommonStoreExtraArgs {
  serverApi: Api
  compId: string
}

export interface Navigation extends RouteParams {
  sectionPath: string
  slug: string
  route: Route
}

export interface RouteParams {
  reservationId?: string
  reservationState?: ReservationState
  thankYouMessageState?: ThankYouMessageState
}

export type ThankYouMessageState = 'yes' | 'no' | 'waiting'

export type ReservationState = 'success' | 'error' | 'pending'

export interface Component {
  id: string
  settings: ComponentSettings
}

export interface ComponentSettings {
  texts: ComponentTexts
  imageRatio: ImageRatio
  buttonStyle: ButtonStyle
  formButtonStyle: ButtonStyle
  imagePosition: ImagePosition
  headerAlignment: Alignment
  contentAlignment: Alignment
  imageOpacity: number
  socialShareVisible: boolean
  aboutSectionVisible: boolean
  shortDateLocationVisible: boolean
  descriptionVisible: boolean
  mapVisible: boolean
  summaryBoxVisible: boolean
  rsvpButtonVisible: boolean
  membersVisible: boolean
}

export enum Route {
  DETAILS = 'details',
  FORM = 'form',
  CHECKOUT = 'checkout',
  TICKETS = 'tickets',
  CHANGE_RSVP = 'change-rsvp',
  ORDER = 'order',
  ONLINE_CONFERENCING = 'oc',
  THANK_YOU_MESSAGES = 'thank-you-messages',
}

export interface CurrentMemberDetails {
  firstName: string
  lastName: string
  email: string
  id: string
}

export interface MemberRsvp {
  rsvp: wix.events.rsvp.Rsvp
  error: RsvpErrorMetadata
  loaded: boolean
}

export interface CheckoutOptionsState {
  loaded: boolean
  options: wix.events.ticketing.GetCheckoutOptionsResponse
}

export interface SelectedTickets {
  [id: string]: number
}

export interface PendingRequests {
  requestNames: string[]
}

export interface TicketsPicker {
  tickets: {[ticketDefId: string]: TicketsPickerTicketInfo}
}

interface TicketsPickerTicketInfo {
  expandedDescription?: boolean
}
