import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types'

type FedopsLoggerConfig = string | {appId: string; widgetId: string}

export const createFedopsLogger = (controller: IWidgetControllerConfig, config: FedopsLoggerConfig) => {
  const {fedOpsLoggerFactory} = controller.platformAPIs
  let isFedopsReport = true
  const fedopsLogger = fedOpsLoggerFactory.getLoggerForWidget(config)

  fedopsLogger.appLoadStarted()

  const onSSRPageReady = () => {
    fedopsLogger.appLoaded()
  }

  const onAppLoaded = () => {
    if (isFedopsReport) {
      fedopsLogger.appLoaded()
      isFedopsReport = false
    }
  }

  return {
    onSSRPageReady,
    onAppLoaded,
  }
}
