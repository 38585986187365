import {getEventId} from '@wix/wix-events-commons-statics'
import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {GetState} from '../types'

export const SHARE_EVENT = 'SHARE_EVENT'
export const GET_EVENT = createActions('GET_EVENT')
export const GET_MEMBERS = createActions('GET_MEMBERS')

export const getEvent = (eventId: string, membersEnabled?: boolean) => (dispatch: Function) => {
  // if (!isDemoEvent()) {
  return dispatch(callAPI(GET_EVENT, eventId, membersEnabled))
  // }
}

export const getMembers = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  if (state.membersAreaEnabled && getEventId(state.event)) {
    return dispatch(callAPI(GET_MEMBERS, getEventId(state.event)))
  }
}
