import {getCurrency, getEventId, isTicketed, BI_ENDPOINTS} from '@wix/wix-events-commons-statics'
import {EventMap} from '@wix/wix-events-commons-statics'
import {VisitorType} from '@wix/wix-events-commons-statics/dist/types/enums/events'
import {FORM_PAGE_LOADED} from '../actions/loaded'
import {REGISTRATION_BUTTON_CLICKED} from '../actions/registration'
import {isRestrictedTo} from '../selectors/event'
import {State} from '../types'

export const eventMap: EventMap = {
  [REGISTRATION_BUTTON_CLICKED]: (state: State) => {
    const ticketed = isTicketed(state.event)
    // const tickets = [] // TODO
    // const selectedTickets = [] // TODO

    return {
      evid: 508,
      //ticket_count: ticketed ? getSelectedTicketsQuantity(selectedTickets) : null,
      //ticket_definition_count: ticketed ? tickets.length : null,
      currency: ticketed ? getCurrency(state.event) : null,
      event_id: getEventId(state.event),
      // total_order_revenue: ticketed ? getTotalOrderRevenue(state) * 100 : null,
      // nonfree_ticket_count: ticketed ? getNonFreeSelectedTicketsCount(state) : null,
      is_member_only_event: isRestrictedTo(state, VisitorType.MEMBER),
    }
  },
  [FORM_PAGE_LOADED]: (state: State) => {
    // const {event, selectedTickets, tickets} = state
    const {event} = state
    const ticketed = isTicketed(event)

    return {
      evid: 509,
      origin: null,
      event_id: getEventId(event),
      currency: ticketed ? getCurrency(event) : null,
      // ticket_count: ticketed ? getSelectedTicketsQuantity(selectedTickets) : null,
      // ticket_definition_count: ticketed ? tickets.length : null,
      // total_order_revenue: ticketed ? getTotalOrderRevenue(state) * 100 : null,
      // nonfree_ticket_count: ticketed ? getNonFreeSelectedTicketsCount(state) : null,
    }
  },
  endpoint: BI_ENDPOINTS.EVENTS_UOU,
}
