import {getEventId} from '@wix/wix-events-commons-statics'
import {TranslationFunction} from 'i18next'
import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {GetState} from '../types'

export const GET_PLAN_LIST = createActions('GET_PLAN_LIST')

export const getPlanList = () => async (dispatch: Function, getState: GetState) =>
  dispatch(callAPI(GET_PLAN_LIST, getEventId(getState().event)))

export const openMembershipPicker = (t: TranslationFunction) => (dispatch: Function, getState: GetState) => {
  // TODO: FIX
  // const state = getState()
  // const slug = getSlug(state.event)
  // const path = isMobile() ? `${slug}/${components.mobileTicketsPicker.id}` : slug
  // // Ensures detection of navigation back from paid plans
  // // Without this line neither mount nor update will happen
  // addPostfix(MEMBERSHIPS_URL_POSTFIX)
  // navigateToSection({
  //   sectionId: PAID_PLANS_SECTION_ID,
  //   appDefinitionId: PAID_PLANS_APP_DEF_ID,
  //   queryParams: {
  //     originAppDefinitionId: EVENTS_APP_ID,
  //     planIds: getNotPurchasedPlanIds(state).join(','),
  //     navigateToSectionProps: btoa(
  //       JSON.stringify({
  //         appDefinitionId: EVENTS_APP_ID,
  //         sectionId: EVENTS_SECTION_ID,
  //         state: path,
  //       }),
  //     ),
  //     verticalStatusContent: btoa(
  //       JSON.stringify({
  //         titleText: t('membership_thankYou_title'),
  //         contentText: t('membership_thankYou_content'),
  //         buttonText: t('membership_thankYou_action'),
  //       }),
  //     ),
  //   },
  // })
}
