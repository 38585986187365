import {AnyAction} from 'redux'
import {NAVIGATE} from '../actions/navigation'
import {Navigation, Route} from '../types'

const defaultState: Navigation = {
  sectionPath: '',
  slug: '',
  route: Route.DETAILS,
}

export const navigation = (state = defaultState, action: AnyAction): Navigation => {
  switch (action.type) {
    case NAVIGATE:
      return {...state, ...action.payload}
    default:
      return state
  }
}
