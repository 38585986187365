import {Route, RouteParams, State} from '../types'

export const getSectionPath = (state: State) => state.navigation.sectionPath

export const getSlug = (state: State) => state.navigation.slug

export const getRoute = (state: State) => state.navigation.route

export const getReservationState = (state: State) => state.navigation.reservationState

export const getThankYouMessageState = (state: State) => state.navigation.thankYouMessageState

export const getUrl = (state: State, route: Route, routeParams: RouteParams) => {
  const url = `/${getSectionPath(state)}/${getSlug(state)}`

  switch (route) {
    case Route.DETAILS:
      return url
    case Route.ORDER:
      return `${url}/${Route.ORDER}/${routeParams.reservationId}/${routeParams.reservationState}`
    case Route.THANK_YOU_MESSAGES:
      return `${url}/${Route.THANK_YOU_MESSAGES}/${routeParams.thankYouMessageState}`
    default:
      return `${url}/${route}`
  }
}
