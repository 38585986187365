import {TAB} from '../../commons/constants/navigation'
import {isEditor} from '../../commons/selectors/environment'
import {DesignSection, TextSection} from '../../commons/types/widget-settings'
import {
  getAdjustedSchedulingDate,
  getEditorReferenceDate,
  getGroupedEventsByDate,
  getReferenceDate,
} from '../selectors/calendar-layout'
import {getEvents} from '../selectors/events'
import {GetState} from '../types/state'
import {SettingsTab} from './settings-panel'

export const ADD_CALENDAR_MONTH = 'ADD_CALENDAR_MONTH'
export const SUB_CALENDAR_MONTH = 'SUB_CALENDAR_MONTH'
export const SET_CALENDAR_TODAY = 'SET_CALENDAR_TODAY'
export const SET_CALENDAR_DATE = 'SET_CALENDAR_DATE'

export const OPEN_MONTHLY_CALENDAR_POPUP = 'OPEN_MONTHLY_CALENDAR_POPUP'
export const CLOSE_MONTHLY_CALENDAR_POPUP = 'CLOSE_MONTHLY_CALENDAR_POPUP'

export const OPEN_MONTHLY_CALENDAR_EVENT = 'OPEN_MONTHLY_CALENDAR_EVENT'
export const CLOSE_MONTHLY_CALENDAR_EVENT = 'CLOSE_MONTHLY_CALENDAR_EVENT'

export const addCalendarMonth = () => (dispatch, getState: GetState) => {
  dispatch({type: ADD_CALENDAR_MONTH, payload: getReferenceDate(getState()).valueOf()})
}

export const subCalendarMonth = () => (dispatch, getState: GetState) => {
  dispatch({type: SUB_CALENDAR_MONTH, payload: getReferenceDate(getState()).valueOf()})
}

export const resetCalendar = () => (dispatch, getState: GetState) => {
  const state = getState()

  if (isEditor(state)) {
    dispatch({type: SET_CALENDAR_DATE, payload: getEditorReferenceDate(state).valueOf()})
  } else {
    dispatch({type: SET_CALENDAR_TODAY})
  }
}

export const openMonthlyCalendarPopup = (timestamp: number) => ({
  type: OPEN_MONTHLY_CALENDAR_POPUP,
  payload: {date: timestamp},
})
export const closeMonthlyCalendarPopup = () => ({type: CLOSE_MONTHLY_CALENDAR_POPUP})

export const openMonthlyCalendarEvent = (timestamp: number, eventId: string) => ({
  type: OPEN_MONTHLY_CALENDAR_EVENT,
  payload: {
    date: timestamp,
    eventId,
  },
})

export const closeMonthlyCalendarEvent = () => ({
  type: CLOSE_MONTHLY_CALENDAR_EVENT,
})

export const openAnyEventDetails = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const referenceDate = getReferenceDate(state)

  if (referenceDate) {
    const anyMonthEvent = getEvents(state).find(
      event =>
        event.scheduling.config.startDate && referenceDate.isSame(getAdjustedSchedulingDate(event.scheduling), 'month'),
    )

    if (anyMonthEvent) {
      dispatch(
        openMonthlyCalendarEvent(getAdjustedSchedulingDate(anyMonthEvent.scheduling).valueOf(), anyMonthEvent.id),
      )
    }
  }
}

export const openAnyEventList = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const referenceDate = getReferenceDate(state)

  if (referenceDate) {
    const groupedEvents = Object.entries(getGroupedEventsByDate(state, referenceDate))
    const multiEventGroup = groupedEvents.find(([, dayEvents]) => (dayEvents as ExtendedEvent[]).length > 1)

    if (multiEventGroup) {
      dispatch(openMonthlyCalendarPopup(Number(multiEventGroup[0])))
    } else if (groupedEvents.length) {
      dispatch(openMonthlyCalendarPopup(Number(groupedEvents[0][0])))
    }
  }
}

export const calendarSettingsTabChanged = (tab: TAB) => {
  // TODO: remove LIST_DISPLAY when merging specs.events.ui.ReactWidgetSettings
  if (tab === TAB.DISPLAY || (tab as any) === SettingsTab.LIST_DISPLAY) {
    return openAnyEventDetails()
  } else {
    return closeMonthlyCalendarPopup()
  }
}

export const calendarSettingsSectionChanged = (id: DesignSection) => {
  if ([DesignSection.EVENT_DETAILS_POPUP, DesignSection.BUTTONS, DesignSection.RIBBONS].includes(id)) {
    return openAnyEventDetails()
  } else if (id === DesignSection.EVENT_LIST_POPUP) {
    return openAnyEventList()
  } else {
    return closeMonthlyCalendarPopup()
  }
}

export const calendarTextsSectionChanged = (id: TextSection) => {
  if ([TextSection.RSVP_CLOSED, TextSection.RSVP].includes(id)) {
    return openAnyEventDetails()
  } else {
    return closeMonthlyCalendarPopup()
  }
}
