import {getRestrictedTo, isRegistrationClosed} from '@wix/wix-events-commons-statics'
import {getAboutText, hasGuestsGoing as commonsHasGuestsGoing, isRsvp} from '@wix/wix-events-commons-statics'
import {VisitorType} from '@wix/wix-events-commons-statics/dist/es/types/enums/events'
import {isEditor} from '../../commons/selectors/environment'
import {State, ThankYouMessageState} from '../types'
import {getSelectedTicketsQuantity} from './selected-tickets'
import {isAboutSectionEnabled} from './settings'

const DEFAULT_GUEST_COUNT = 3
export const getDefaultGuestTotal = (isRsvpEvent: boolean): TotalEventGuests =>
  isRsvpEvent ? {YES: DEFAULT_GUEST_COUNT} : DEFAULT_GUEST_COUNT

export const getFormMessages = (event: wix.events.Event, state: ThankYouMessageState) => {
  const {negativeMessages, waitlistMessages, positiveMessages} = event.form.messages.rsvp
  switch (state) {
    case 'no':
      return negativeMessages
    case 'waiting':
      return waitlistMessages
    default:
      return positiveMessages
  }
}

export const hasGuestsGoing = (state: State) => {
  const totalGuests = state.event?.totalGuests ?? 0
  return !!totalGuests && commonsHasGuestsGoing(totalGuests, isRsvp(state.event))
}

export const getTotalGuests = (state: State): TotalEventGuests => {
  const isRsvpEvent = isRsvp(state.event)
  const areGuestsGoing = hasGuestsGoing(state)
  if (!areGuestsGoing && isEditor(state)) {
    return getDefaultGuestTotal(isRsvpEvent)
  }
  return state.event.totalGuests
}

export const getMembers = (state: State): Member[] => state.event.members || []

export const isAboutSectionVisible = (state: State) =>
  isAboutSectionEnabled(state.component.settings) && (isEditor(state) || !!getAboutText(state.event))

export const isDemoEvent = (state: State) => false // TODO

export const isCheckoutButtonDisabled = (state: State) =>
  isRegistrationClosed(state.event) || !getSelectedTicketsQuantity(state.selectedTickets)

export const isRestrictedTo = (state: State, visitorType: VisitorType) =>
  state.membersAreaEnabled && getRestrictedTo(state.event) === visitorType
