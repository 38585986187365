import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types'
import {GenericAPI} from '@wix/wix-events-commons-statics'
import {instanceGetterFactory} from '../services/instance'
import {getLinguisticHeader, getMultilingualParams} from '../services/multilingual'
import {getServerBaseUrl} from './wix-code-api'

export class Api {
  controller: IWidgetControllerConfig
  getInstance: () => string
  instanceId: string
  compId: string
  viewMode: string
  language: string
  api: GenericAPI
  registrar: Dictionary<Function>

  constructor(controller: IWidgetControllerConfig) {
    this.controller = controller
    this.getInstance = instanceGetterFactory(controller)
    this.instanceId = controller.appParams.instanceId
    this.compId = controller.compId
    this.viewMode = controller.wixCodeApi.window.viewMode.toLowerCase()
    this.language = controller.wixCodeApi.site.language
    this.api = new GenericAPI(getServerBaseUrl(controller.wixCodeApi), () => this.getHeaders(controller))
  }

  getHeaders = (controller: IWidgetControllerConfig) => {
    const linguisticHeader = getLinguisticHeader(controller)

    const headers = [
      ['Authorization', this.getInstance()],
      ['Content-Type', 'application/json'],
    ]

    if (linguisticHeader) {
      headers.push(['x-wix-linguist', linguisticHeader])
    }

    return headers
  }

  get(name: string) {
    const api = this.registrar[name]

    if (api) {
      return api
    }

    throw `API METHOD IS NOT REGISTERED ${name}`
  }

  appendLinguisticParams = (path: string) => {
    const linguisticParams = getMultilingualParams(this.controller)
    return linguisticParams ? `${path}${linguisticParams}` : path
  }

  appendPetriOvr = (path: string) => {
    const {petri_ovr} = this.controller.wixCodeApi.location.query
    return petri_ovr ? `${path}&petri_ovr=${petri_ovr}` : path
  }
}
