import {AnyAction} from 'redux'
import {PaidPlansState} from '../types'

const defaultState: PaidPlansState = {
  enabled: false,
  planList: [],
  purchasedPlanList: [],
  selectedPaidPlanId: null,
}

export const paidPlans = (state = defaultState, action: AnyAction): PaidPlansState => {
  switch (action.type) {
    // case SET_PAID_PLANS_ENABLED:
    //   return {...state, enabled: true}
    // case SUCCESS[GET_PLAN_LIST]:
    //   const planList = getSortedPlanList(action.payload.plans)

    //   return {
    //     ...state,
    //     planList,
    //     purchasedPlanList: planList.filter(plan => plan.purchased),
    //     selectedPaidPlanId: null,
    //   }
    default:
      return state
  }
}
