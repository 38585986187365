import {IWixAPI} from '@wix/native-components-infra/dist/es/src/types/types'
import {getExternalLink, isRegistrationClosed, isTicketed} from '@wix/wix-events-commons-statics'
import {isEditor, isMobile, isSite} from '../../commons/selectors/environment'
import {isDetailsPageEnabled} from '../../commons/selectors/site-settings'
import {getUrl} from '../selectors/navigation'
import {GetState, Route, RouteParams, State, StoreExtraArgs, ThankYouMessageState} from '../types'
import {parsePath} from '../utils/navigation'

export const NAVIGATE = 'NAVIGATE'

export const navigate = (route: Route, routeParams: RouteParams = {}) => async (
  dispatch: Function,
  getState: GetState,
  {wixCodeApi}: StoreExtraArgs,
) => {
  const state = getState()
  const {route: validRoute, routeParams: validRouteParams} = validateRoute(state, route, routeParams)

  dispatch({type: NAVIGATE, payload: {route: validRoute, ...validRouteParams}})

  if (isSite(state)) {
    return wixCodeApi.location.to(getUrl(state, validRoute, validRouteParams))
  }
}

export const validateRoute = (state: State, route: Route, routeParams: RouteParams) => {
  switch (route) {
    case Route.DETAILS:
      return {route: validateDetailsRoute(state), routeParams}
    case Route.FORM:
      return {route: validateFormRoute(state), routeParams}
    case Route.CHANGE_RSVP:
      return {route: validateChangeRsvpRoute(state), routeParams}
    case Route.TICKETS:
      return {route: validateTicketsRoute(state), routeParams}
    default:
      return {route, routeParams}
  }
}

const validateDetailsRoute = (state: State) => (isDetailsPageEnabled(state.siteSettings) ? Route.DETAILS : Route.FORM)

const validateFormRoute = (state: State) => (isTicketed(state.event) ? Route.DETAILS : Route.FORM)

const validateChangeRsvpRoute = (state: State) => (isTicketed(state.event) ? Route.DETAILS : Route.CHANGE_RSVP)

const validateTicketsRoute = (state: State) => {
  if (isEditor(state) && !isMobile(state)) {
    // Probably opened desktop editor after playing in mobile preview
    return Route.DETAILS
  }

  return Route.TICKETS
}

export const navigateToForm = () => navigate(Route.FORM)

export const navigateToThankYouMessage = (thankYouMessageState: ThankYouMessageState) =>
  navigate(Route.THANK_YOU_MESSAGES, {thankYouMessageState})

//TODO fix
export const navigateToMainPage = () => navigate(Route.DETAILS)

export const handleExternalRedirect = (wixCodeApi: IWixAPI, state: State) => {
  const externalLink = getExternalLink(state.event)
  const registrationClosed = isRegistrationClosed(state.event)
  const shouldRedirectIfExternal = isSite(state) && Boolean(externalLink) && !registrationClosed
  const route = parsePath(wixCodeApi.location.path).route
  const redirectingRoutes = [Route.CHECKOUT, Route.DETAILS, Route.FORM, Route.TICKETS]

  if (redirectingRoutes.includes(route) && shouldRedirectIfExternal) {
    wixCodeApi.location.to(externalLink)
  }
}
