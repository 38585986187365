import {AnyAction} from 'redux'
import {GET_MEMBER_RSVP, RESET_RSVP_ERROR, SEND_RSVP, SKIP_GET_MEMBER_EVENT_RSVP} from '../actions/rsvp'
import {MemberRsvp, RsvpErrorKey} from '../types'

const getDefaultState = (): MemberRsvp => ({
  rsvp: null,
  error: {},
  loaded: false,
})

export const memberRsvp = (state = getDefaultState(), action: AnyAction) => {
  switch (action.type) {
    case GET_MEMBER_RSVP.SUCCESS:
      return {...state, rsvp: action.payload, loaded: true}
    case SEND_RSVP.SUCCESS:
      return {...state, rsvp: action.payload.rsvp, loaded: true}
    // case REQUEST[DELETE_RSVP]:
    //   return {...state, rsvp: null, loaded: true}
    // case REQUEST[UPDATE_RSVP_STATUS]:
    //   const [, , status] = action.args
    //   return {...state, rsvp: {...state.rsvp, status}}
    case SEND_RSVP.FAILURE:
      return {...state, error: action.error?.payload?.metadata ?? {error_key: RsvpErrorKey.UNKNOWN_RSVP_ERROR}}
    case RESET_RSVP_ERROR:
      return {...state, error: {}}
    case GET_MEMBER_RSVP.FAILURE:
      return {...state, loaded: true}
    case SKIP_GET_MEMBER_EVENT_RSVP:
      return {...state, loaded: true}
    default:
      return state
  }
}
