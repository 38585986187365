import {Multilingual} from '../types/state'

const defaultState: Multilingual = {
  isEnabled: false,
  currentLanguage: null,
}

export const multilingual = (state = defaultState) => {
  return state
}
