import {Navigation, ReservationState, Route, RouteParams, ThankYouMessageState} from '../types'

const getRoute = (path: string[]): Route => {
  return (path[2] || Route.DETAILS) as Route
}

export const getRouteParams = (path: string[]): RouteParams => {
  switch (getRoute(path)) {
    case Route.ORDER:
      return {
        reservationId: path[3],
        reservationState: path[4] as ReservationState,
      }
    case Route.THANK_YOU_MESSAGES:
      return {
        thankYouMessageState: path[3] as ThankYouMessageState,
      }
    default:
      return {}
  }
}

export const parsePath = (path: string[]): Navigation => {
  return {
    sectionPath: path[0],
    slug: path[1] || '',
    route: getRoute(path),
    ...getRouteParams(path),
  }
}
