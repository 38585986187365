import {
  getEventId,
  getEventTitle,
  isFull,
  isNoResponseEnabled,
  isTicketed,
  BI_ORIGINS,
  ChangeRsvpModalMode,
  MembersModal,
} from '@wix/wix-events-commons-statics'
import {VisitorType} from '@wix/wix-events-commons-statics/dist/types/enums/events'
import {RsvpStatus} from '@wix/wix-events-commons-statics/dist/types/enums/rsvp'
import * as _ from 'lodash'
import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {isEditor} from '../../commons/selectors/environment'
import {openDialogModal} from '../../commons/services/modal'
import {getCurrentMemberId} from '../../commons/utils/members-api'
import {getCurrentMemberDetails} from '../selectors/current-member-details'
import {isRestrictedTo} from '../selectors/event'
import {isDemoEvent} from '../selectors/event'
import {getMemberEventRsvpStatus, getMemberRsvpId, memberRsvpExists} from '../selectors/member-rsvp'
import {shouldNavigateToForm} from '../selectors/rsvp'
import {GetState, RegFormData, RsvpError, State, StoreExtraArgs, ThankYouMessageState} from '../types'
import {extractFormData} from '../utils/api-data-mapper'
import {getEvent} from './event'
import {ensureLoggedIn} from './members'
import {navigateToForm, navigateToThankYouMessage} from './navigation'

export const RESET_RSVP_ERROR = 'RESET_RSVP_ERROR'
export const SEND_RSVP = createActions('SEND_RSVP')
export const UPDATE_RSVP = createActions('UPDATE_RSVP')
export const UPDATE_RSVP_STATUS = createActions('UPDATE_RSVP_STATUS')
export const DELETE_RSVP = createActions('DELETE_RSVP')
export const GET_MEMBER_RSVP = createActions('GET_MEMBER_RSVP')
export const SKIP_GET_MEMBER_EVENT_RSVP = 'SKIP_GET_MEMBER_EVENT_RSVP'

export const getMemberRsvp = () => (dispatch: Function, getState: GetState, {wixCodeApi}) => {
  const state = getState()
  if (state.membersAreaEnabled) {
    const currentMemberId = getCurrentMemberId(wixCodeApi)
    if (currentMemberId && !isDemoEvent(state) && !isTicketed(state.event)) {
      return dispatch(callAPI(GET_MEMBER_RSVP, getEventId(state.event), currentMemberId))
    }
  }
  return dispatch({type: SKIP_GET_MEMBER_EVENT_RSVP})
}

export const resetRsvpError = () => ({type: RESET_RSVP_ERROR})

export const sendRsvp = (eventId: string, guest: RegFormData) => async (dispatch: Function, getState: GetState) => {
  const state = getState()

  // if (isDemoEvent() || isDemoMode()) {
  //   return navigateToThankYouMessages(eventId, 'yes')
  // }

  const promise = dispatch(
    callAPI(SEND_RSVP, eventId, extractFormData(guest), guest.response, getCurrentMemberDetails(state)?.id),
  )

  if (state.membersAreaEnabled) {
    promise.catch((e: RsvpError) => {
      if (e.payload?.metadata?.member_already_registered) {
        // dispatch(promptLogin())
      } else {
        throw e
      }
    })
  }

  const response = await promise

  if (!_.isEmpty(response.rsvp)) {
    dispatch(navigateToThankYouMessage(RsvpStatus[guest.response].toLowerCase() as ThankYouMessageState))
  }

  return response
}

export const updateRsvp = (eventId: string, guest: RegFormData) => async (dispatch: Function, getState: GetState) => {
  const state = getState()

  // if (isDemoEvent() || isDemoMode()) {
  //   return navigateToThankYouMessages(eventId, 'yes')
  // }

  const response = await dispatch(
    callAPI(UPDATE_RSVP, eventId, extractFormData(guest), guest.response, getMemberRsvpId(state)),
  )

  if (!_.isEmpty(response.rsvp)) {
    dispatch(navigateToThankYouMessage(RsvpStatus[guest.response].toLowerCase() as ThankYouMessageState))
  }

  return response
}

export const updateRsvpStatus = (eventId: string, rsvpId: string, status: RsvpStatus) =>
  callAPI(UPDATE_RSVP_STATUS, eventId, rsvpId, status)

export const deleteRsvp = (eventId: string, rsvpId: string) => callAPI(DELETE_RSVP, eventId, rsvpId)

export const updateRsvpOrNavigateToForm = (state: State, dispatch: Function, resolve: Function) => {
  const {event} = state
  const eventId = getEventId(event)
  const rsvpId = getMemberRsvpId(state)

  if (shouldNavigateToForm(state)) {
    navigateToForm()
    return resolve(true)
  }

  dispatch(updateRsvpStatus(eventId, rsvpId, RsvpStatus.YES))
}

export const changeMemberRsvp = () => (
  dispatch: Function,
  getState: GetState,
  {wixCodeApi, compId}: StoreExtraArgs,
): Promise<boolean> => {
  return new Promise(resolve => {
    const state = getState()
    const {event} = state
    const eventTitle = getEventTitle(event)
    const eventId = getEventId(event)
    const params = {event: eventTitle, eventId, origin: BI_ORIGINS.EVENT_DETAILS}

    if (isEditor(state)) {
      return openDialogModal({
        type: MembersModal.NO_CANCEL_RSVP,
        params,
        compId,
        wixCodeApi,
        onClose: () => resolve(false),
      })
    }

    const noEnabled = isNoResponseEnabled(event)
    const status = getMemberEventRsvpStatus(state)
    const mode = getChangeRsvpModalMode(state)

    openDialogModal({
      type: MembersModal.UPDATE_RSVP,
      params: {...params, mode},
      compId,
      wixCodeApi,
      onClose: () => resolve(false),
      onConfirm: async () => {
        const rsvpId = getMemberRsvpId(state)

        if (!memberRsvpExists(state)) {
          dispatch(navigateToForm())
          return resolve(true)
        }

        if (noEnabled) {
          if ([RsvpStatus.YES, RsvpStatus.WAITING].includes(status)) {
            dispatch(updateRsvpStatus(eventId, rsvpId, RsvpStatus.NO))
          } else if (getMemberEventRsvpStatus(state) === RsvpStatus.NO) {
            isFull(event)
              ? dispatch(updateRsvpStatus(eventId, rsvpId, RsvpStatus.WAITING))
              : updateRsvpOrNavigateToForm(state, dispatch, resolve)
          }
        } else {
          await dispatch(deleteRsvp(eventId, rsvpId))
        }

        resolve(true)
        dispatch(getEvent(eventId, state.membersAreaEnabled))
      },
    })
  })
}

const getChangeRsvpModalMode = (state: State) => {
  const {event} = state
  const noEnabled = isNoResponseEnabled(event)
  const status = getMemberEventRsvpStatus(state)

  if (!memberRsvpExists(state)) {
    return ChangeRsvpModalMode.UPDATE_TO_YES
  }

  if (!noEnabled) {
    return ChangeRsvpModalMode.DELETE
  }

  if (isFull(event) && status === RsvpStatus.NO) {
    return ChangeRsvpModalMode.WAITLIST
  }

  if (status === RsvpStatus.NO) {
    return ChangeRsvpModalMode.UPDATE_TO_YES
  }

  return ChangeRsvpModalMode.UPDATE_TO_NO
}

export const ensureLoginForMembersOnly = () => async (dispatch: Function, getState: GetState) => {
  const state = getState()

  if (isRestrictedTo(state, VisitorType.MEMBER)) {
    return dispatch(ensureLoggedIn())
  } else {
    return true
  }
}
