import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {isRequestPending} from '../selectors/pending-requests'
import {GetState, SelectedTickets} from '../types'

export const RESERVE_TICKETS = createActions('RESERVE_TICKETS')
export const CANCEL_RESERVATION = createActions('CANCEL_RESERVATION')

export const reserveTickets = (eventId: string, selectedTickets: SelectedTickets, slug: string) => (
  dispatch: Function,
) => {
  // TODO: FIX
  // const state = getState()
  // const upgradeNeeded = isUpgradeNeeded(state)
  // TODO: fix
  // const owner = isOwner()

  // if (upgradeNeeded && isMobile(state) /** || !owner **/) {
  //   return openDialogModal({type: 'checkout-unavailable', onClose: () => window.location.reload()})
  // }

  // if (upgradeNeeded /** && owner **/) {
  //   return openOnstageModal('upgrade-to-premium', (options: {message: {proceed?: any}} = {message: {}}) => {
  //     if (options.message.proceed) {
  //       dispatch(makeReservation(eventId, selectedTickets, slug))
  //     }
  //   })
  // }

  return dispatch(makeReservation(eventId, selectedTickets, slug))
}

export const cancelReservation = (eventId: string, reservationId: string) => (dispatch: Function) => {
  if (reservationId) {
    dispatch(callAPI(CANCEL_RESERVATION, eventId, reservationId))
  }
}

const makeReservation = (eventId: string, tickets: SelectedTickets, slug: string) => async (
  dispatch: Function,
  getState: GetState,
) => {
  const state = getState()

  if (!isRequestPending(state, RESERVE_TICKETS.REQUEST)) {
    // TODO: fix
    // createReservationStarted()
    await dispatch(callAPI(RESERVE_TICKETS, eventId, tickets))
    // isMobile(state) ? navigateToMobileCheckout(slug) : navigateToTicketForm(slug)
  }
}
