import {callAPI, createActions} from '../../commons/actions/data-action-helper'

export const GET_DISCOUNT = createActions('GET_DISCOUNT')
export const CLICK_ENTER_COUPON = 'CLICK_ENTER_COUPON'
export const SET_COUPON_CODE = 'SET_COUPON_CODE'
export const RESET_COUPON_CODE = 'RESET_COUPON_CODE'
export const COUPON_APPLIED = 'COUPON_APPLIED'

export const enterCouponClicked = () => ({
  type: CLICK_ENTER_COUPON,
})

export const setCouponCode = (couponCode: string) => ({
  type: SET_COUPON_CODE,
  payload: couponCode,
})

export const resetCouponCode = () => ({
  type: RESET_COUPON_CODE,
})

export const getDiscount = (eventId: string, reservationId: string, couponCode: string) => async (
  dispatch: Function,
) => {
  const response = await dispatch(callAPI(GET_DISCOUNT, eventId, reservationId, couponCode))

  if (response.invoice?.discount) {
    dispatch({type: COUPON_APPLIED})
  }
}
