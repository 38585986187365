import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {getLanguage} from '../../commons/selectors/environment'
import {getCurrentMemberDetails} from '../selectors/current-member-details'
import {GetState} from '../types'

export const PROMPT_LOGIN = createActions('PROMPT_LOGIN')

export const ensureLoggedIn = () => (dispatch: Function, getState: GetState) => {
  if (getCurrentMemberDetails(getState())) {
    return true
  }

  return dispatch(promptLogin())
}

export const promptLogin = () => (dispatch, getState: GetState) =>
  dispatch(callAPI(PROMPT_LOGIN, getLanguage(getState())))
