import {Action} from 'redux'
import {CurrentMemberDetails} from '../types'

const defaultState: CurrentMemberDetails = {
  id: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
}

export const currentMemberDetails = (state = defaultState, action: Action) => {
  switch (action.type) {
    default:
      return state
  }
}
