import {AnyAction} from 'redux'

const defaultState: wix.events.ticketing.Order = {}

export const order = (state = defaultState, action: AnyAction): wix.events.ticketing.Order => {
  switch (action.type) {
    default:
      return state
  }
}
