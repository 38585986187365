import {SelectedTickets, State} from '../types'
import {getTicketOrderIndexById} from './tickets'

export const getSelectedTicketsQuantity = (state: SelectedTickets) =>
  Object.keys(state).reduce((sum: number, ticketId: string) => state[ticketId] + sum, 0)

export const getSelectedTicketQuantity = (state: SelectedTickets, ticketId: string) => state[ticketId] || 0

export const getSortedSelectedTickets = (state: State): SelectedTickets => {
  const nonZero = (ticketDefId: string) => Boolean(state.selectedTickets[ticketDefId])

  const byTicketOrder = (first: string, second: string) =>
    getTicketOrderIndexById(state.tickets, first) - getTicketOrderIndexById(state.tickets, second)

  return Object.keys(state.selectedTickets)
    .filter(nonZero)
    .sort(byTicketOrder)
    .reduce(
      (selectedTickets: SelectedTickets, id: string) => ({
        ...selectedTickets,
        [id]: state.selectedTickets[id],
      }),
      {},
    )
}
