import {RegFormData} from '../types'

export const extractFormData = (data: RegFormData) => {
  const nonFormKeys = ['reservation', 'response']
  const formData = {} as RegFormData

  Object.entries(data).forEach(([key, value]) => {
    if (!nonFormKeys.includes(key)) {
      if (key.includes('address') && value) {
        if (typeof value === 'string') {
          formData[key] = [value]
        } else {
          formData[key] = []

          // Does not work with map or for-in/of due to empty value (empty != undefined)
          for (let i = 0; i < (value as string[]).length; i++) {
            (formData[key] as string[])[i] = value[i] || ''
          }
        }
      } else if (key === 'additionalGuests') {
        formData[key] = value.toString()
      } else {
        formData[key] = value as string | string[]
      }
    }
  })

  return formData
}

// const RSVP_STATUS_MAP = {
//   [OLD_RSVP_STATUS.YES]: RsvpStatus.YES,
//   [OLD_RSVP_STATUS.NO]: RsvpStatus.NO,
//   [OLD_RSVP_STATUS.WAITING]: RsvpStatus.WAITING,
// }
//
// export const mapNewRsvpStatusToOld = (newStatus: RsvpStatus) =>
//   Object.entries(RSVP_STATUS_MAP).find(([, newValue]) => newValue === newStatus)[0]
