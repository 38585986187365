import {AnyAction} from 'redux'
import {UPDATE_STYLE_PARAMS} from '../actions/component'
import {Component, ComponentSettings} from '../types'

const defaultState: Component = {
  id: null,
  settings: {} as ComponentSettings,
}

export const component = (state = defaultState, action: AnyAction): Component => {
  switch (action.type) {
    case UPDATE_STYLE_PARAMS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload.numbers,
          ...action.payload.booleans,
        },
      }
    default:
      return state
  }
}
